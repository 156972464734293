<template>
  <div class="d-flex justify-content-start">
    <!-- <b-avatar :src="params.data.image" class="flex-shrink-0 mr-2" size="30px" @click="$router.push(url)" /> -->
    <!-- <router-link :to="url" class="link-button text-inherit text-dark hover:text-primary" @click.stop.prevent> -->
    {{ params.value }}
    <!-- </router-link> -->
  </div>
</template>

<script>

export default {
  name: 'CellRendererLink',
  computed: {
    url() {
      // return `/user-manager/${ params.data.id }`
      return `/user-manager/${this.params.data.email}`

      // Below line will be for actual product
      // Currently it's commented due to demo purpose - Above url is for demo purpose
      // return "/apps/user/user-view/" + this.params.data.id
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

a:hover {
  color: $primary  !important;
}
</style>
