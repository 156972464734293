<template>
  <div class="self-center">
    <b-badge
      v-if="params.account_approval_status === 'pending'"
      pill
      class="h-6"
      variant="warning"
    >
      Pending
    </b-badge>
    <b-badge
      v-else
      pill
      class="h-6"
      variant="success"
    >
      Approved
    </b-badge>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

export default {
  name: 'CellRendererAccessGranted',
  components: {
    BBadge,
  },
  props: {
    params: Object,
  },
}
</script>

<style lang="scss" scoped>

</style>

