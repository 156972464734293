<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div id="page-user-list">
    <b-modal
      v-model="edit_popup"
      size="lg"
      centered
      hide-footer
      :title="edit_section == 'edit' ? 'Edit User Details' : 'Add User Details'"
    >
      <div class="vx-col w-full">
        <!-- FIELD: fullname -->
        <b-row class="mx-1 my-2">
          <b-form-input
            v-if="edit_section == 'edit'"
            v-model="edit_data.fullname"
            :state="$checkFullName(edit_data.fullname).state"
            class="w-full"
            placeholder="Full Name"
          />
          <b-form-row
            v-else
            class="w-100 mb-2"
          >
            <b-col class="">
              <b-form-input
                v-model="edit_data.fullname"
                class="w-100"
                :state="$checkFullName(edit_data.fullname).state"
                danger-text="Please enter a valid full name"
                placeholder="Full Name"
              />
              <b-form-invalid-feedback>
                {{ $checkFullName(edit_data.fullname).message }}
              </b-form-invalid-feedback>
            </b-col>

          </b-form-row>
        </b-row>

        <!-- FIELD: email -->
        <b-row class="mx-1 my-2">
          <b-form-input
            v-if="edit_section == 'edit'"
            v-model="edit_data.email_address"
            disabled
            class="w-full"
            placeholder="Email Address"
          />
          <b-form-row
            v-else
            class="w-100 mb-2"
          >
            <b-col class="">
              <b-form-input
                v-model="edit_data.email_address"
                class="w-100"
                :state="!email_check"
                danger-text="Please enter a valid email address"
                placeholder="Email Address"
              />
              <b-form-invalid-feedback>
                Please enter a valid email address.
              </b-form-invalid-feedback>
            </b-col>

          </b-form-row>
        </b-row>

        <!-- FIELD: phone_number -->
        <b-row class="mx-1 my-2">
          <b-form-input
            v-if="edit_section == 'edit'"
            v-model="edit_data.phone_number"
            :state="$checkPhoneNumber(edit_data.phone_number).state"
            disabled
            class="w-full"
            placeholder="Phone Number"
            type="number"
            maxlength="8"
          />

          <b-form-row
            v-else
            class="w-100 mb-2"
          >
            <b-col class="">
              <b-form-input
                v-model="edit_data.phone_number"
                class="w-100"
                :state="$checkPhoneNumber(edit_data.phone_number).state"
                danger-text="Please enter a phone number"
                placeholder="Phone Number"
                type="number"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                :formatter="$limitPhoneNumber"
              />
              <b-form-invalid-feedback>
                {{ $checkPhoneNumber(edit_data.phone_number).message }}
              </b-form-invalid-feedback>
            </b-col>
          </b-form-row>
        </b-row>

        <!-- FIELD: team -->
        <div
          class="mx-1 my-2"
        >
          <b-form-input
            v-if="edit_section == 'edit'"
            v-model="edit_data.team"
            class="w-full"
            placeholder="Team / Department"
          />
          <b-form-row
            v-else
            class="w-100 mb-2"
          >
            <b-col class="">
              <b-form-input
                v-model="edit_data.team"
                class="w-100"
                :state="$checkTeam(edit_data.team).state"
                danger-text="Please enter a valid team or department"
                placeholder="Team / Department"
              />
              <b-form-invalid-feedback>
                {{ $checkTeam(edit_data.team).message }}
              </b-form-invalid-feedback>
            </b-col>
          </b-form-row>
        </div>

        <b-row
          v-if="
            $store.state.auth.ActiveUser.account_type === this.$AccountTypes.EDUCATION_INSTITUTION
          "
          class="mx-1 my-2"
        >
          <b-form-input
            v-if="
              $store.state.auth.ActiveUser.company_id.includes('persolkelly')
            "
            v-model="edit_data.recruiter_number"
            class="w-full"
            placeholder="Recruiter Licence Number"
          />
          <b-form-input
            v-else
            v-model="edit_data.recruiter_number"
            class="w-full"
            placeholder="Recruiter Registration Number"
          />
        </b-row>

        <b-row
          v-if="edit_data.company_id ? edit_data.company_id.includes('persolkelly') : false"
          class="mx-1 my-2"
        >
          <v-select
            v-model="edit_data.organization"
            :placeholder="'Choose an organization'"
            class="selectExample w-100 cursor-pointer"
            :options="employers"
            label="value"
          />
        </b-row>

        <b-row
          v-if="
            edit_data.company_id
              ? $store.state.auth.ActiveUser.account_type === this.$UserGroups.AGENCY && (edit_data.company_id !== 'e2i' && edit_data.company_id !== 'EMPLOYMENT AND EMPLOYABILITY INSTITUTE PTE. LTD.')
              : false"
          class="mx-1"
        >
          <b-form-input
            v-model="edit_data.agency_number"
            class="w-full"
            placeholder="EA License Number"
          />
        </b-row>

        <b-row
          v-if="edit_data.company_id ? edit_data.company_id.includes('persolkelly') : false"
          class="mx-1 my-1"
        >
          <b-form-checkbox
            v-model="edit_data.view_all"
            class="w-full"
          >Allow view all data</b-form-checkbox>
        </b-row>

        <b-row
          v-if="edit_data.company_id ? edit_data.company_id.includes('persolkelly') : false"
          class="mx-1 my-1"
        >
          <b-form-checkbox
            v-model="edit_data.whitelist"
            class="w-full"
          >Whitelist user for telegram</b-form-checkbox>
        </b-row>

        <b-row class="mx-1 mb-2 mt-1">
          <b-form-checkbox
            v-model="edit_data.two_factor_active"
            class="w-full"
          >Turn on two-factor authentication</b-form-checkbox>
        </b-row>

        <hr>

        <b-row class="mx-1">
          <b-col
            v-if="edit_section !== 'edit'"
            cols="12"
            class="mx-1 my-1"
          >
            <b-form-checkbox
              v-model="edit_data.send_through_system"
              class="w-full"
            >Send login details through HeadHunters HQ system</b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            class="w-100 mb-1"
          >
            <b-button
              variant="warning"
              class="w-100 "
              @click="generatePass"
            >
              Generate new password
            </b-button>
          </b-col>

          <b-col>
            <b-alert
              v-if="!edit_data.send_through_system && show_pass"
              class="mt-1 mx-1"
              variant="warning"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
                />
                <span class="ml-25">Please copy the new generated password because it wont be available
                  once you close this screen.</span>
              </div>

            </b-alert>
          </b-col>
          <b-col
            cols="12"
            class="w-100 mb-1"
          >
            <b-form-input
              v-model="generated_pass"
              :class="`w-full ${!edit_data.send_through_system && show_pass ? '' : 'd-none' }`"
              @click="copyTextToClipboard()"
            />
          </b-col>
        </b-row>

        <hr>
        <!-- FIELD: role -->
        <b-row class="mx-1 mb-1 mt-1">
          <h6>Choose the role for this user</h6>
          <b-form-row
            class="w-100"
          >
            <b-col class="">
              <v-select
                id="role"
                ref="role"
                v-model="selected_role_id"
                :placeholder="
                  edit_data.role !== undefined
                    ? edit_data.role
                    : 'Pick a role or assign permissions to this user'
                "
                class="selectExample w-full"
                :options="roleOptions"
                :reduce="role => role.value"
                label="text"
                @input="repopulatePermissions"
              />

              <b-form-invalid-feedback tooltip>
                Please select a valid role.
              </b-form-invalid-feedback>
            </b-col>
          </b-form-row>
        </b-row>

        <permissions-card
          v-if="renderPermissionsCard"
          :user-permissions="edit_data.permissions"
          @change="handlePermissions"
        />

        <hr>
        <div class="mx-1">
          <div class="vx-col w-full">
            <ButtonSpinner :is-loading="isButtonLoading.all">
              <b-button
                class="mr-3 mt-1 mb-1"
                variant="primary"
                :disabled="!$checkFullName(edit_data.fullname).state || !$checkPhoneNumber(edit_data.phone_number).state || !$checkTeam(edit_data.team).state || email_check"
                @click="saveChanges"
              >{{
                edit_section == 'edit' ? 'Save Changes' : 'Add User'
              }}</b-button>
            </ButtonSpinner>
          </div>
        </div>
      </div>
    </b-modal>

    <b-overlay
      rounded="sm"
    >
      <b-card class="">
        <b-row class="button-container">
          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <!-- ACTION - DROPDOWN -->
          <b-col
            cols="12"
            xl="12"
            md="12"
            class="d-flex flex-wrap mt-xs-1 w-100"
            :class="{'justify-content-between': (isTablet), 'justify-content-end': (!isTablet && !isMobile)}"
          >
            <b-row
              v-if="$route.name === 'user-manager'"
              :align-h="isTablet ? 'between' : 'end'"
              class="mr-0 w-100"
              :class="{'m-0 mt-0 mb-1': isMobile}"
            >

              <b-col
                col
                cols="12"
                md="auto"
                class="mt-1 mt-md-0 px-0"
              >
                <b-dropdown
                  :style="`height: 40px; ${ isMobile ? 'width: 100%' : (isTablet ? 'min-width: 150px' : 'min-width: 180px')}`"
                  split
                  :text="action_text"
                  right
                  :variant="action_view"
                  :icon="action_icon"
                  type="filled"
                  class="ml-md-1 cursor-pointer"
                  @click="performAction"
                >
                  <b-dropdown-item @click="changeAction('Actions')">
                    <span class="d-flex justify-content-start">
                      <feather-icon
                        icon="InfoIcon"
                        size="18"
                        class="mr-1"
                      />
                      <span class="w-32">Actions</span>
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$checkRights(this.$route.meta.section, 'write')"
                    @click="changeAction('Add User')"
                  >
                    <span class="d-flex justify-content-start">
                      <feather-icon
                        icon="PlusIcon"
                        size="18"
                        class="mr-1"
                      />
                      <span class="w-32 -mt-1">Add User</span>
                    </span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="$store.state.auth.ActiveUser.user_group.includes(this.$UserGroups.SUPERUSER) && $checkRights(this.$route.meta.section, 'update')"
                    @click="changeAction('Manage User Roles')"
                  >
                    <span class="d-flex justify-content-start">
                      <feather-icon
                        icon="BookIcon"
                        size="18"
                        class="mr-1"
                      />
                      <span class="w-32">Manage User Roles</span>
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
              <b-col
                col
                cols="12"
                md="auto"
                class="mt-1 mt-md-0 px-0"
              >
                <ButtonSpinner :is-loading="isLoading">
                  <b-button
                    variant="primary"
                    class="ml-md-1"
                    :style="`height: 40px; ${ isMobile ? 'width: 100%' : 'min-width: 150px' }`"
                    @click="exportToCSV"
                  >Export as CSV</b-button>
                </ButtonSpinner>
              </b-col>

            </b-row>
          </b-col>
        </b-row>

        <!-- AgGrid Table -->
        <div
          class="p-1"
          style="min-width: 50vw; min-height: 20vw;width: 100%; height: 100%;"
        >
          <vue-good-table
            mode="remote"
            :pagination-options="{
              enabled: true,
              perPage: serverParams.perPage,
              perPageDropdown: [5, 10, 20, 50, 100],
              dropdownAllowAll: false,
            }"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: false, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :is-loading.sync="isLoading"
            :total-rows="totalRecords"
            :rows="dataRows"
            :columns="columnDefs"

            styleClass="vgt-table condensed bordered"
            compactMode
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            @on-selected-rows-change="onSelectionChange"
          >

            <template
              slot="column-filter"
              slot-scope="props"
            >
              <span
                v-if="props.column.field === 'created_on'"
                class="mt-2"
              >
                <!-- <flat-pickr
                  v-model="created_on_filter"
                  :config="configPicker"
                  class="vgt-input"
                  placeholder="Filter Added On"
                  @input="(value) => updateFilters(props.column.field, value)"
                /> -->

                <b-button
                  variant="warning"
                  @click="showDateRangeFilter(true, props.column.field)"
                >
                  Click to filter the date
                </b-button>
                <b-button
                  v-if="props.column.field in serverParams.columnFilters"
                  class="ml-2"
                  @click="resetDateRangeFilter(true, props.column.field)"
                >Reset</b-button>
              </span>
            </template>

            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Status -->
              <span v-if="props.column.field === 'account_approved'">
                <CellRendererStatus :params="{account_approved: props.row.account_approved}" />
              </span>
              <span v-else-if="props.column.field === 'account_approval_status'">
                <CellRendererApprovalStatus :params="{account_approval_status: props.row.account_approval_status}" />
              </span>

              <span v-else-if="props.column.field === 'actions'">

                <CellRendererActions
                  :params="{
                    account_approval_status: props.row.account_approval_status,
                    email_address: props.row.email_address,
                    id: props.row.user_id,
                    account_approved: props.row.account_approved
                  }"
                  @refresh-record="get_company_users()"
                />
              </span>

              <!-- Column: Common -->
              <span v-else>

                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

          </vue-good-table>
        </div>

      </b-card>

      <!-- Popup Role List -->
      <!-- <b-modal
        v-model="manage_role_popup"
        centered
        size="lg"
        hide-footer
        title="Add User Role"
      >
        <manage-role-modal
          :show-modal="manage_role_popup"
        />
      </b-modal> -->

      <!-- Manage Role Popup -->
      <b-modal
        v-model="manage_role_popup"
        centered
        size="lg"
        hide-footer
        title="Add User Role"
      >
        <b-row class="mt-1 mx-1">
          <b-button
            class="my-2"
            variant="primary"
            @click="$router.push({ name: 'roles-create' })"
          >
            Add User Role
          </b-button>
        </b-row>

        <b-row class="mt-1 mx-1 w-100">
          <b-card
            no-body
            class="w-100 mr-2"
          >
            <b-card-body>
              <b-card-title>Roles</b-card-title>
              <b-card-sub-title>Contains roles you have previously created</b-card-sub-title>
            </b-card-body>

            <b-table
              striped
              responsive
              :fields="rolesTableFields"
              :items="rolesData"
              :per-page="rolesPerPage"
              :current-page="rolesCurrentPage"
              class="mb-0"
            >
              <template #cell(name)="data">
                {{ data.value }}
              </template>
              <template #cell(description)="data">
                <div style="max-width: 20vw;">
                  {{ data.value }}
                </div>
              </template>
              <template #cell(actions)="data">
                <div
                  class="self-center"
                  style="min-width: 30%;"
                >
                  <div class="d-flex flex-row flex-wrap justify-content-end">
                    <!-- View -->
                    <b-button
                      v-b-tooltip.hover.top="'View Role'"
                      class="btn-icon grid-btn"
                      variant="outline-dark"
                      @click="$router.push({ name: 'roles-view', params: { id: data.item.id } })"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        svg-classes="hover:text-primary stroke-current"
                      />
                    </b-button>

                    <!-- Edit -->
                    <b-button
                      v-b-tooltip.hover.top="'Edit Role'"
                      class="btn-icon grid-btn"
                      variant="outline-dark"
                      @click="$router.push({ name: 'roles-edit', params: { id: data.item.id } })"
                    >
                      <feather-icon
                        class="text-warning"
                        icon="EditIcon"
                        svg-classes="hover:text-primary stroke-current"
                      />
                    </b-button>

                    <!-- Delete -->
                    <b-button
                      v-b-tooltip.hover.top="'Delete this role'"
                      class="btn-icon grid-btn"
                      variant="outline-dark"
                      @click="confirmDeleteRole(data.item)"
                    >
                      <feather-icon
                        class="text-danger"
                        icon="Trash2Icon"
                        svg-classes="hover:text-primary stroke-current"
                      />
                    </b-button>
                  </div>
                </div>
              </template>
            </b-table>

            <div class="w-100">
              <b-pagination
                v-model="rolesCurrentPage"
                class="my-1 float-right mr-2"
                :total-rows="rolesData.length"
                :per-page="rolesPerPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card>
        </b-row>
      </b-modal>

      <!-- User Role popup Will remove late -->
      <b-modal
        v-model="user_role_popup"
        centered
        size="lg"
        hide-footer
        title="Add User Role"
      >
        <b-row class="mt-1 mx-1">
          <h6>Role Name</h6>
          <b-form-input
            class="form-control-merge"
            name="role-name"
            placeholder="Role Name"
          />
        </b-row>
        <b-row class="mt-1 mx-1">
          <h6>Role Description</h6>

          <b-form-textarea
            rows="4"
            class="w-full"
            placeholder="Role Name"
          />
        </b-row>
        <b-row class="mt-1 mx-1 w-100">
          <b-card
            no-body
            class="w-100 mr-2"
          >
            <b-card-body>
              <b-card-title>Permissions</b-card-title>
              <b-card-sub-title>Permission according to roles</b-card-sub-title>
            </b-card-body>
            <b-table
              striped
              responsive
              :items="permissionsData"
              class="mb-0"
            >

              <template #cell(module)="data">
                {{ data.value }}
              </template>
              <template #cell()="data">
                <b-form-checkbox
                  disabled
                  :checked="data.value"
                />
              </template>

            </b-table>
            <b-button
              class="my-2"
              variant="primary"
            >
              Add User Role
            </b-button>
          </b-card>
        </b-row>

      </b-modal>
    </b-overlay>

    <b-modal
      v-model="dateRangeFilter"
      centered
      hide-footer
      title="Choose the desired date range to filter the data"
      class="bg-danger"
      size="lg"
    >
      <v-date-picker
        v-model="startEndDate"
        mode="date"
        :masks="masks"
        color="orange"
        :columns="2"
        is-range
        is-expanded
      />
    </b-modal>
  </div>
</template>

<script>
// import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { dateRangePickerMixins } from '@/components/date-range-picker/dateRangePickerMixins'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  VBTooltip,
  BOverlay,
  BFormCheckbox,
  BAlert,
  BFormInvalidFeedback,
  BPagination,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormRow,
  BFormTextarea,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
} from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import PermissionsCard from './components/PermissionsCard.vue'
import ManageRoleModal from './components/manage-role-modal/ManageRoleModal.vue'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererApprovalStatus from './cell-renderer/CellRendererApprovalStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'

// Floating Filter
import StatusFloatingFilterComponent from './floating-filter/StatusFloatingFilterComponent.js'

export default {
  components: {
    BCard,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BFormCheckbox,
    BAlert,
    BPagination,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormInput,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormTextarea,
    BFormRow,
    BFormInvalidFeedback,
    VueGoodTable,
    vSelect,

    PermissionsCard,
    ManageRoleModal,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererApprovalStatus,
    CellRendererVerified,
    CellRendererActions,
    flatPickr,

    // Floating Filter
    StatusFloatingFilterComponent,
  },
  mixins: [onResizeMixins, dateRangePickerMixins],
  props: {
    specifiedCompanyId: {
      type: String,
      default: 'nil',
    },
  },
  data() {
    return {
      isLoading: true,
      isButtonLoading: {
        all: false,
      },
      // b-table configuration
      rolesTableFields: [
        {
          key: 'name',
          label: 'Role Name',
          sortable: true,
        },
        {
          key: 'description',
        },
        {
          key: 'actions',
          thClass: 'text-right',
          tdClass: 'float-right',
        },
      ],
      rolesPerPage: 10,
      rolesCurrentPage: 1,

      // permissions
      renderPermissionsCard: true,
      selected_role_id: null,
      roleOptions: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 10,
      },
      rolesData: [],
      dataRows: [],

      selectedRows: null,
      permissionsData: [
        {
          module: 'Analytics',
          read: true,
          write: true,
          create: true,
          delete: true,
        },
        {
          module: 'Data Manager',
          read: true,
          write: true,
          create: true,
          delete: true,
        },
        {
          module: 'Events Manager',
          read: true,
          write: true,
          create: true,
          delete: true,
        },
        {
          module: 'Audit Logs',
          read: true,
          write: true,
          create: true,
          delete: true,
        },
        {
          module: 'User Manager',
          read: true,
          write: true,
          create: true,
          delete: true,
        },
        {
          module: 'View NRIC Number',
          read: true,
          write: true,
          create: true,
          delete: true,
        },
        {
          module: 'Download User Data',
          read: true,
          write: true,
          create: true,
          delete: true,
        },
      ],

      rowHeight: null,
      select_company: '',
      employers: ['P-SERV PTE LTD', 'PERSOLKELLY SINGAPORE PTE. LTD.'],
      // employers: [
      //   {
      //     text: 'P-SERV PTE LTD',
      //     value: 'P-SERV PTE LTD#199002727Z' },
      //   {
      //     text: 'PERSOLKELLY SINGAPORE PTE. LTD.',
      //     value: 'PERSOLKELLY SINGAPORE PTE. LTD.#199103521E',
      //   },
      // ],
      blur_check: 'blur',
      email_check: true,
      edit_section: 'edit',
      section: {},
      perm: {},
      show_pass: false,
      context: null,
      generated_pass: '',
      edit_data: {
        fullname: '',
        email_address: '',
        team: '',
        company: '',
        recruiter_number: '',
        agency_number: '',
        two_factor: false,
        organization: '',
        whitelist: false,
        view_all: false,
        send_through_system: false,

        permissions: {},
      },
      edit_popup: false,
      action_view: 'dark',
      action_text: 'Actions',
      action_icon: 'info_outline',

      // Filter Options
      user_role_popup: false,
      manage_role_popup: false,
      usersData: [],
      searchQuery: '',

      configPicker: {
        altInput: true,
        enableTime: false,
      },
      created_on_filter: null,
      columnDefs: [
        {
          label: 'Full Name',
          field: 'fullname',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          cellRendererFramework: 'CellRendererLink',
        },
        {
          label: 'Added On',
          field: 'created_on',
          width: '320px',
          type: 'date',
          dateInputFormat: 'do MMM yyyy HH:mm', // expects 2018-03-16
          dateOutputFormat: 'do MMM yyyy HH:mm', // outputs Mar 16th 2018
          tdClass: 'text-left',
          thClass: 'text-left',
          filterOptions: {
            customFilter: true,
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          valueFormatter: params => this.$formatDate(params.value),
        },
        {
          label: 'User Role',
          field: 'role_name',
          filterOptions: {

            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Account Status',
          field: 'account_approved',

          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
            filterDropdownItems: [
              { value: 'active', text: 'Active' },
              { value: 'deactivated', text: 'Deactivated' },
            ],
          },
        },
        {
          label: 'Actions',
          field: 'actions',
          minWidth: 200,
          sortable: false,
          type: 'rightAligned',
          tdClass: 'text-right',
          cellRendererFramework: 'CellRendererActions',
        },
      ],

      exportingCSVIndex: null,
      exportingCSV: false,

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererApprovalStatus,
        CellRendererVerified,
        CellRendererActions,
      },
    }
  },
  computed: {

  },
  watch: {
    edit_popup(val) {
      if (!val) {
        this.edit_data = {
          fullname: '',
          email_address: '',
          team: '',
          company: '',
          recruiter_number: '',
          agency_number: '',
          two_factor: false,
          whitelist: false,
          organization: '',
          view_all: false,
          send_through_system: false,
        }
        this.perm = {}
        this.section = {}
        this.show_pass = false
        this.generated_pass = ''
        this.edit_section = 'edit'
        this.get_company_users()
      }
    },
    'edit_data.email_address': function (val, oldVal) {
      this.email_check = !this.$checkEmail(val).state
    },
    'edit_data.organization': function (val, oldVal) {
      if (val.startsWith('P-SERV')) {
        if (this.edit_data.agency_number.length < 3) {
          this.edit_data.agency_number = '90C3494'
        } else if (this.edit_data.agency_number == '01C4394') {
          this.edit_data.agency_number = '90C3494'
        }
      } else if (this.edit_data.agency_number.length < 3) {
        this.edit_data.agency_number = '01C4394'
      } else if (this.edit_data.agency_number == '90C3494') {
        this.edit_data.agency_number = '01C4394'
      }
      // this.$forceUpdate();
    },
  },
  created() {
    if (this.$route.name === 'user-manager') {
      const custom_column = {
        label: 'Email Address',
        field: 'email_address',
        filterOptions: {
          trigger: 'enter',
          enabled: true,
          placeholder: '',
        },
        // pinned: true,
      }
      this.columnDefs.splice(0, 1, custom_column)
    }

    if (this.check_rights('read')) {
      if (this.$store.state.auth.ActiveUser.company_name.includes('persolkelly')) {
        const custom_column = {
          label: 'Team',
          field: 'team',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        }
        this.columnDefs.splice(2, 0, custom_column)
      }

      if (this.$store.state.auth.ActiveUser.user_group.includes(this.$UserGroups.SUPERUSER)) {
        const access_granted_column = {
          label: 'Approval Status',
          field: 'account_approval_status',
          sortable: true,
          minWidth: 150,
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
            filterDropdownItems: [
              { value: 'approved', text: 'Approved' },
              { value: 'pending', text: 'Pending' },
            ],
          },
          cellRendererFramework: 'CellRendererApprovalStatus',
        }

        this.columnDefs.splice(this.columnDefs.length - 1, 0, access_granted_column)
      }
      this.blur_check = ''
      if (!moduleUserManagement.isRegistered) {
        this.$store.registerModule('userManagement', moduleUserManagement)
        moduleUserManagement.isRegistered = true
      }
      this.get_company_users()
      this.get_roles()
    } else {
      this.blur_check = 'blur'
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Inadequate permission',
            icon: 'CheckCircleIcon',
            text: 'You dont have enough rights to view the data in this section',
            variant: 'danger',
            position: 'center',
          },
        },
        { timeout: this.$longestTimeout },
      )
    }
  },
  beforeMount() {
    this.context = { componentParent: this }
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      this.get_company_users()
    },
    onPageChange(params) {
      const prevSelectedROws = this.selectedRows
      this.updateParams({ page: params.currentPage })
      this.updateParams({ selectedRows: prevSelectedROws })
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },
    onColumnFilter(params) {
      this.serverParams.page = 1
      this.updateParams(params)
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      currentFilters[column] = value
      this.updateParams({ columnFilters: currentFilters })
    },

    get_company_users() {
      return new Promise((resolve, reject) => {
        const { token } = this.$store.state.auth.ActiveUser
        const { company_id } = this.$store.state.auth.ActiveUser

        this.$http.defaults.headers.common.Authorization = `Token ${token}`
        this.isLoading = true
        let specified_company_id = 'nil'
        if (this.specifiedCompanyId !== 'nil') {
          specified_company_id = this.specifiedCompanyId
        }

        if (this.$route.name === 'user-manager') {
          specified_company_id = company_id
        }

        // server pagination
        let query = ''
        if (this.exportingCSV) { // if it is not for csv will append pagination details else will not paginate and will retrieve all data
          this.serverParams.extractCSV = true

          if (!this.$isEmpty(this.selectedRows)) {
            const selectedRowsIdArray = []
            this.selectedRows.forEach(row => {
              selectedRowsIdArray.push(row.user_id)
            })

            this.serverParams.selectedRows = selectedRowsIdArray
          }
        }

        query = `?params=${JSON.stringify(this.serverParams)}`

        this.$http
          .get(`/api/all/company-users/${specified_company_id}${query}`, {
            onDownloadProgress(progressEvent) {
              const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
              const parent = document.querySelector('#exportToCSV')
              if (parent) {
                parent.querySelector('.progress-bar').style = `width: ${uploadPercentage}%;`
              }
            },
          })
          .then(response => {
            if (this.exportingCSV) {
              const headers = []

              this.columnDefs.forEach(column => {
                if (column.label.toLowerCase() === 'actions') {
                  return
                }
                headers.push({
                  label: column.label,
                  columnName: column.field,
                })
              })

              // extra field on csv
              headers.push({
                label: 'Last Login',
                columnName: 'last_login',
              })

              const csvData = response.data.users
              csvData.forEach(item => {
                const newItem = item
                if (newItem.account_approved) {
                  newItem.account_approved = 'active'
                } else {
                  newItem.account_approved = 'deactivated'
                }
                return newItem
              })

              this.$downloadCSV(csvData, `User Manager - ${this.$formatDatetime(new Date())}.csv`, headers)
              this.exportingCSV = false
              this.serverParams.extractCSV = false
            } else {
              this.dataRows = response.data.users
              this.pagination = response.data.pagination
              this.totalRecords = response.data.totalRecords
            }
            this.isLoading = false

            const parent = document.querySelector('#exportToCSV')
            if (parent) {
              parent.querySelector('.progress-bar').style = `width: ${100}%;`

              setTimeout(() => {
                this.$toast.dismiss(this.exportingCSVIndex)
              }, this.$longestTimeout)
            }

            resolve()
          })
          .catch(() => {
            this.isLoading = false
            this.$toastDanger('Operation Failed', 'Could not retrieve the company user list. Please try again later!')

            const parent = document.querySelector('#exportToCSV')
            if (parent) {
              parent.querySelector('.progress-bar').style = `width: ${100}%;`

              setTimeout(() => {
                this.$toast.dismiss(this.exportingCSVIndex)
              }, this.$longestTimeout)
            }

            reject()
          })
      })
    },
    get_roles() {
      this.$http
        .get('/api/roles')
        .then(response => {
          const { roles } = response.data
          this.rolesData = roles

          this.roleOptions = [
            {
              text: 'Custom',
              value: 'custom',
            },
          ]
          roles.forEach(role => {
            this.roleOptions.push({
              text: role.name,
              value: role.id,
            })
          })
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Operation Failed', 'Could not retrieve the Role list')
        })
    },
    delete_role(id) {
      const { token } = this.$store.state.auth.ActiveUser

      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.$http
        .delete(`/api/roles/${id}`, {})
        .then(response => {
          const { success, message } = response.data
          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Account Operations',
                  icon: 'CheckCircleIcon',
                  text: message,
                  variant: 'success',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )

            this.get_roles()
            this.get_company_users()
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Account Operations',
                  icon: 'AlertCircleIcon',
                  text: message,
                  variant: 'danger',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Operation Failed', 'Could not delete role, please try again later')
        })
    },

    get_users_with_this_role(id) {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`/api/roles/${id}/users`, {})
          .then(response => {
            const { success, output } = response.data
            if (success) {
              resolve(response)
            }
          })
          .catch(error => {
            reject()
          })
      })
    },
    confirmDeleteRole(role) {
      if (this.$checkRights(this.$route.meta.section, 'delete')) {
        this.get_users_with_this_role(role.id)
          .then(response => {
            const { output, success } = response.data

            if (output.length > 0) {
              this.$bvModal
                .msgBoxConfirm(
                  // `this will also delete "${role.name}" assigned on other user, Are you sure?`,
                  `There are ${output.length} user(s) using this role, Are you sure you want to delete this role?, user with this role will be updated with minimal permissions`,
                  {
                    title: 'Confirm Role Deletion',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Delete',
                    cancelTitle: 'Cancel',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                  },
                )
                .then(value => {
                  if (value) {
                    this.delete_role(role.id)
                  }
                })
            } else {
              this.$bvModal
                .msgBoxConfirm(
                  'Delete this Role?',
                  {
                    title: 'Confirm Role Deletion',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Delete',
                    cancelTitle: 'Cancel',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                  },
                )
                .then(value => {
                  if (value) {
                    this.delete_role(role.id)
                  }
                })
            }
          })
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Inadequate Permission',
              icon: 'CheckCircleIcon',
              text: 'You dont have enough rights to delete data in this section',
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },

    check_rights(right) {
      // let checker = (arr, target) => target.every((v) => arr.includes(v));
      const { permissions } = this.$store.state.auth.ActiveUser
      const currentPagePermission = permissions.filter(item => item.section === 'User Manager')[0]
      const currentPermission = currentPagePermission[right]
      if (currentPermission) {
        return true
      }
      return true
    },
    saveChanges() {
      const approved_perms = Object.keys(
        Object.fromEntries(
          Object.entries(this.perm).filter(([key, value]) => value === true),
        ),
      )
      const approved_sections = Object.keys(
        Object.fromEntries(
          Object.entries(this.section).filter(([key, value]) => value === true),
        ),
      )

      // validate
      const validationMessage = this.validateUserFormData()
      if (this.$isEmpty(validationMessage)) {
        if (this.edit_section === 'edit') {
          this.updateUsersData(
            approved_perms,
            approved_sections,
            this.generated_pass,
          )
        } else {
          this.createUserAccount(approved_perms, approved_sections)
        }
      } else {
        this.$toastDanger('Incomplete Form', validationMessage)
      }
    },
    validateUserFormData() {
      const messages = []

      const checkedFullName = this.$checkFullName(this.edit_data.fullname)
      if (!checkedFullName.state) {
        messages.push(checkedFullName.message)
      }

      if (this.email_check) {
        messages.push('A valid Email is required\n')
      }

      const checkedPhoneNumber = this.$checkPhoneNumber(this.edit_data.phone_number)
      if (!checkedPhoneNumber.state) {
        messages.push(checkedPhoneNumber.message)
      }

      // if (this.$isEmpty(this.edit_data.team)) {
      //   message += 'A valid Team Name is required\n'
      // }

      if (this.$isEmpty(this.selected_role_id)) {
        messages.push('Please select a Role or assign permissions')
      }

      return messages
    },
    createUserAccount(perms, sections) {
      const { company_id, account_type } = this.$store.state.auth.ActiveUser
      let user_group = []
      let hiring_type = 'recruitment agency'

      if (company_id == 'e2i') {
        user_group = ['admin', 'agency']
      } else if (this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY) {
        user_group = ['admin', 'agency']
      } else if (
        this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.EDUCATION_INSTITUTION
      ) {
        user_group = ['admin', 'education_institution']
        hiring_type = 'education_institution'
      } else {
        user_group = ['admin', 'employer']
      }

      const data = {
        fullname: this.edit_data.fullname,
        email_address: this.edit_data.email_address.toLowerCase(),
        phone_number: this.edit_data.phone_number,
        account_type,
        team: this.edit_data.team,
        password: this.generated_pass,
        location: 'global',
        auto_confirm: true,
        company_id,
        hiring: hiring_type,
        recruiter_number: this.edit_data.recruiter_number,
        agency_number: this.edit_data.agency_number,
        whitelist: this.edit_data.whitelist,
        view_all: this.edit_data.view_all,
        send_through_system: this.edit_data.send_through_system,
        role: this.edit_data.role,
        role_id: this.edit_data.role_id,
        permissions: this.edit_data.permissions,
        two_factor_active: true,
      }

      if (this.edit_data.organization !== undefined) {
        if (this.edit_data.organization.length > 3) {
          data.organization = this.edit_data.organization.split('#')[0]
        }
      }
      if (user_group) {
        data.user_group = user_group
      }

      if (company_id) {
        data.company_id = company_id
      }

      if (this.edit_data.team) {
        data.team = this.edit_data.team
      }
      if (this.edit_data.fullname) {
        data.fullname = this.edit_data.fullname
      }

      if (this.edit_data.recruiter_number) {
        data.recruiter_number = this.edit_data.recruiter_number
      }

      if (this.edit_data.agency_number) {
        data.agency_number = this.edit_data.agency_number
      }

      this.isButtonLoading.all = true
      this.$http
        .post('/api/auth/register', {
          user: data,
        })
        .then(response => {
          const output = response.data
          // this.$set(this, 'edit_data', { ...output, ...this.edit_data})
          this.edit_data = { ...output, ...this.edit_data }

          if (output) {
            // this.updateUsersData(
            //   perms,
            //   sections,
            //   '',
            //   (user_group = user_group),
            //   (company_id = company_id),
            // )
            this.edit_popup = false
            this.$toastSuccess('Account Creation Update', `${output.email_address} account has been created successfully.`)
          } else {
            this.$toastDanger('Account Creation Failed', 'Please try again later.')
          }
          this.isLoading = false
          this.isButtonLoading.all = false
        })
        .catch(error => {
          this.isButtonLoading.all = false
          this.$handleErrorResponse(error, 'Account Creation Failed', error.response.data.errors.body[0])
            .then(() => {
              this.isLoading = false
            })
        })
    },
    updateUsersData(
      approved_perms,
      approved_sections,
      password,
      user_group = '',
      company_id = '',
    ) {
      const { token } = this.$store.state.auth.ActiveUser
      const email = this.edit_data.email_address.toLowerCase()
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.isLoading = true

      const data = {
        id: this.edit_data.id,
        perm_rights: approved_perms,
        perm_sections: approved_sections,
        two_factor_active: this.edit_data.two_factor_active,
        company_id: this.$store.state.auth.ActiveUser.company_id,
        whitelist: this.edit_data.whitelist,
        view_all: this.edit_data.view_all,
      }

      if (this.edit_data.organization !== undefined) {
        if (this.edit_data.organization.length > 3) {
          data.organization = this.edit_data.organization.split('#')[0]
          // this.select_company.split("#")[1],
        }
      }

      if (user_group) {
        data.user_group = user_group
      }

      if (company_id) {
        data.company_id = company_id
      }

      if (password) {
        data.password = password
      }

      if (this.edit_data.team) {
        data.team = this.edit_data.team
      }

      if (this.edit_data.fullname) {
        data.fullname = this.edit_data.fullname
      }

      if (this.edit_data.recruiter_number) {
        data.recruiter_number = this.edit_data.recruiter_number
      }

      if (this.edit_data.agency_number) {
        data.agency_number = this.edit_data.agency_number
      }

      this.isButtonLoading.all = true
      this.$http
        // .put(`/api/company-user/${id}`, {
        .put('/api/company-user', {
          user: data,
        })
        .then(response => {
          const output = response.data
          if (output.success) {
            this.edit_popup = false
            this.$toastSuccess('Update Successful', 'Your changes have been saved.')
          } else {
            this.$toastDanger('Update Failed', 'You changes have failed')
          }
          this.isLoading = false
          this.isButtonLoading.all = false
        })
        .catch(error => {
          this.$$handleErrorResponse(error, 'Operation Failed', 'Could not retrieve the company user list. Please try again later.')

          this.isLoading = false
          this.isButtonLoading.all = false
        })
    },
    deleteSelected() {
      this.get_company_users()
    },
    copyTextToClipboard() {
      const text = this.generated_pass
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        err => {},
      )
      this.$toastSuccess('CheckCircleIcon', 'Text copied successfully')
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16))
    },
    generatePass() {
      const uniq = this.uuidv4()
      this.generated_pass = uniq
      this.show_pass = true
    },
    changeAction(text) {
      this.action_text = text
      switch (text) {
        case 'Add User':
          this.action_view = 'success'
          this.action_icon = 'add'
          break
        case 'Delete User':
          this.action_view = 'danger'
          this.action_icon = 'delete'
          break
        case 'Actions':
          this.action_view = 'dark'
          this.action_icon = 'info_outline'
          break
        default:
          this.action_view = 'dark'
          this.action_icon = 'info_outline'
      }

      this.performAction()
    },
    performAction() {
      if (this.action_text === 'Add User') {
        if (this.check_rights('write')) {
          this.edit_popup = true
          this.edit_data.company_id = this.$store.state.auth.ActiveUser.company_id
          this.edit_section = 'add'
          this.generated_pass = this.uuidv4()
          this.show_pass = true
        } else {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Inadequate permissions',
                icon: 'CheckCircleIcon',
                text: 'You dont have enough rights to add data in this section',
                variant: 'danger',
                position: 'center',
              },
            },
            { timeout: this.$longestTimeout },
          )
        }
      } else if (this.action_text === 'Manage User Roles') {
        this.manage_role_popup = true
        this.get_roles()
      }
    },

    onClickCloseButton() {
      this.$emit('closePopup')
    },
    createPerms(rights, accessibles) {
      const perms = {}
      const sections = {}
      for (const right of rights) {
        perms[right] = true
      }

      for (const section of accessibles) {
        sections[section] = true
      }

      this.perm = perms
      this.section = sections
    },
    methodFromParent(popup, edit_data) {
      this.edit_popup = popup
      this.edit_data = edit_data
      this.createPerms(edit_data.perm_rights, edit_data.perm_sections)
    },
    handleInput() {
      const text = document.getElementsByClassName(
        'input-select v-select--input',
      )[0].value
      this.getEmployers(text)
    },
    getEmployers(query) {
      this.employers = []
      this.$http
        .get(`/api/company-search/${query}`)
        .then(response => {
          if (response.data.success) {
            for (const item of response.data.data) {
              const index = this.employers.findIndex(x => x.text == item.text)
              if (index === -1) {
                this.employers.push({
                  text: item.text,
                  value: item.value,
                })
              }
            }
          } else {
            this.event_exist = false
          }
        })
        .catch(error => {})
    },

    checkFullName(fullname) {
      let state = false
      let message = ''
      const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/

      if (!(fullname.length > 2)) {
        state = true
        message = 'A valid fullname is required (only letters allowed)'
      }

      if (format.test(fullname)) {
        state = true
        message = 'A valid fullname is required (only letters allowed)'
      }

      return [state, message]
    },
    checkPhoneNumber(value) {
      let state = false
      let message = ''

      if (value == null || value.length < 8) {
        state = true
        message = 'phone number must be at least 8 digit'
      } else {
        state = false
        message = ''
      }

      return [state, message]
    },

    // permissions
    repopulatePermissions() {
      this.$http
        .get(`/api/roles/${this.selected_role_id}`)
        .then(response => {
          const role = response.data

          this.$delete(this.edit_data, 'permissions')
          this.$set(this.edit_data, 'permissions', role.permissions)
          this.$set(this.edit_data, 'role', role.name)
          this.$set(this.edit_data, 'role_id', role.id)

          // to force permissioncard to display the new permissions
          this.renderPermissionsCard = false
          setTimeout(() => {
            this.renderPermissionsCard = true
          }, 100)
        })
        .catch(() => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Something went wrong',
                icon: 'AlertCircleIcon',
                text: 'Please try again later',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },
    handlePermissions(permissions) {
      this.edit_data.permissions = permissions
      this.selected_role_id = 'custom'
      this.$set(this.edit_data, 'role', 'custom')
    },

    exportToCSV() {
      // retrieve data from db
      this.exportingCSV = true

      this.exportingCSVIndex = this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Compiling CSV',
            icon: 'AlertCircleIcon',
            text: 'Please wait a moment',
            variant: 'warning',
            progress: 0,
            progressId: 'exportToCSV',
          },
        },
        { timeout: 99999999 },
      )

      this.get_company_users()
    },
  },
}
</script>

<style lang="scss" scoped>
.blur {
  filter: blur(16px);
}

.ag-center-cols-viewport {
  overflow-x: hidden !important;
}

.card-body {
  // reduce gap on b-card
  padding-block: 0;
  padding-inline: 1rem;
}

.button-container {
  padding: 0;
  margin-block: 1rem;
}
</style>
